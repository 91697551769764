export const baseRemSize = 16;

export const API_URL = '/api';
export const API_COMMON_ERROR_MSG = '잠시후 다시 시도해주세요.';

const Constants = {
    OK: '확인',
    CANCEL: '취소',
    REG: '등록',
    MOD: '변경',
    DELETE: '삭제',
    LIST: '목록',
    CLOSE: '닫기',
    CONFM: '승인',
    RETURN: '반려',
    READING_SPINE_NAME: 'Cobb\'s angle',
    READING_HANDS_NAME: '뼈 나이',
    XRAY_TYPE_SPINE: 'spine',
    XRAY_TYPE_HANDS: 'hands',
    XRAY_TYPE_BOTH: 'both',
    SPINE_APEX: 'Apex',
    SPINE_DIRECTION: '휜 방향',
    STATE_REQST: '신청대기',
    STATE_CONFM: '이관완료',
    STATE_CANCL: '신청취소',
    STATE_RETURN: '신청반려'
}

export default Constants;