import "./sass/app.css"
import "./sass/base.css"
import "./sass/common.css"
import "./sass/style.css"
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import mixin from './mixin'
//import VueHead from 'vue-head'
import './plugins'
import scrollLock from "v-body-scroll-lock";
import '@mdi/font/css/materialdesignicons.css';
import htmlToPdf from './plugins/htmlToPdf';
import VueApexCharts from 'vue-apexcharts';
import Toasted from 'vue-toasted';
import VueViewer from 'v-viewer';
import VueLazyload from 'vue-lazyload'
import VueShepherd from 'vue-shepherd'
import VueProgressBar from 'vue-progressbar'

Vue.mixin(mixin)
//Vue.use(VueHead)
Vue.use(scrollLock)
Vue.use(htmlToPdf);
Vue.use(VueApexCharts)
Vue.use(Toasted)
Vue.use(VueViewer)
Vue.use(VueShepherd)

var req = new XMLHttpRequest();
req.open('GET', document.location, false);
req.send(null);

Vue.component('apexchart', VueApexCharts)

const loadimage = require('@/assets/commonImage/img_loading.gif')

const options = {
  color: '#4750EB',
  failedColor: '#ED4C67',
  thickness: '4px',
}

let title = '';
if (process.env.VUE_APP_PINE_DX === 'C') {
  title =  'Pine-DXC'
} else if (process.env.VUE_APP_PINE_DX === 'B') {
  title = 'Pine-DXB'
} else {
  title = 'Pine-DXS'
}

document.title = title;

Vue.use(VueProgressBar, options)

Vue.use(VueLazyload, {
  preLoad: 1,
  error: loadimage,
  loading: loadimage,
  attempt: 1
})

Vue.config.productionTip = false
const vue = new Vue({
  App,
  scrollLock,
  vuetify,
  store,
  router,
  render: h => h(App),
})
vue.$mount('#app')
window.thisVue = vue

document.ondragstart = () => { return false; } 
