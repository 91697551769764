<template>
  <v-app :class="[window.width > 1025 ? '' : 'app']">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <vue-progress-bar></vue-progress-bar>
    <v-dialog v-model="commPop.isShow" width="320">
      <DefaultPopup
            :popupText="commPop.msg"
            :secondTxt="CONST_OK"
            :firstBtn="false"
            :borderRadius="4"
            :onClick="() => {
              commPop.isShow = false;
              if(commPop.status == 401) $router.go();
            }"
          @closePopup="commPop.isShow = false"
        />
    </v-dialog>
  </v-app>
</template>

<script>

export default {
  name: "App",
  metaInfo: {
    meta: [
      {
        name: "description",
        content: "",
      },
      {
        property: "og:title",
        content: "",
      },
      {
        property: "og:description",
        content: "",
      },
      {
        property: "og:image",
        content: "//" + window.location.host + require("../public/favicon.png"),
      },
    ],
  },
  components: {},
  data() {
    return {
      commPop: {
        isCreate: false,
        isShow: false,
        currentPageNo: null,
        msg: "",
        status: 200,
      },
    };
  },
  watch: {
  },
  async created() {},
  mounted() {
    window.addEventListener(
      "load",
      function () {
        setTimeout(scrollTo, 0, 0, 1);
      },
      false
    );
    this.eventOn("SHOW_POPUP", (msg, status) => {
      this.commPop.isShow = true;
      this.commPop.msg = msg;
      this.commPop.status = status;
    });
  },
  activated() {},
  deactivated() {},
  destroyed() {},
  methods: {},
};
</script>
<style lang="scss" >
.app {
  height: calc(var(--vh, 1vh) * 100);
  .v-application--wrap {
    min-height: calc(var(--vh, 1vh) * 100) !important;
  }
}
::v-deep .v-dialog__content{
  z-index: 205;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
